import { AxiosResponse } from 'axios';

import { IUser } from '../store/slices/userSlice.ts';
import { Report } from '../types';

import { backend } from './configs/axios';

export const urls = {
  user: {
    profile: 'users/profile/',
  },
  requests: {
    newWalletTradeAnalyze: 'requests/new-trade-analyze',
    analyzeWalletOnHoneypot: 'requests/analyze-wallet-on-honeypot',
    myWalletTradeAnalyzeRequests: 'requests/my-wallet-trade-analyze-requests',
    myWalletTradeAnalyzeRequestById:
      'requests/my-wallet-trade-analyze-requests',
    saveComment: 'requests/my-wallet-trade-analyze-requests',
  },
};

export interface IMetaReport {
  currentPage: number;
  itemCount: number;
  itemsPerPage: number;
  totalItems: number;
  totalPages: number;
}

export const userApi = {
  url: backend.defaults.baseURL,
  getProfile: async function (headers = {}): Promise<AxiosResponse<IUser>> {
    return await backend.request({
      method: 'GET',
      url: `${userApi.url}/${urls.user.profile}`,
      headers,
    });
  },
  getWalletTradeAnalyzeRequests: async function (
    page: number,
    limit: number,
    headers = {}
  ): Promise<AxiosResponse<{ data: Report[]; meta: IMetaReport }>> {
    return await backend.request({
      method: 'GET',
      url: `${userApi.url}/${urls.requests.myWalletTradeAnalyzeRequests}?page=${page}&limit=${limit}`,
      headers,
    });
  },
  getWalletTradeAnalyzeRequestById: async function (
    id: string,
    headers = {}
  ): Promise<{ data: Report }> {
    return await backend.request({
      method: 'GET',
      url: `${userApi.url}/${urls.requests.myWalletTradeAnalyzeRequests}/${id}`,
      headers,
    });
  },
  analyzeWalletOnHoneypot: async function (
    address: string,
    chain: string,
    headers = {}
  ): Promise<AxiosResponse<{ isHoneypot: boolean }>> {
    return await backend.request({
      method: 'GET',
      url: `${userApi.url}/${urls.requests.analyzeWalletOnHoneypot}/${address}`,
      headers,
      params: {
        chain: chain,
      },
    });
  },
  saveComment: async function (
    walletId: string,
    internalWalletId: string,
    comment: string,
    headers = {}
  ): Promise<AxiosResponse<{ isHoneypot: boolean }>> {
    return await backend.request({
      method: 'POST',
      url: `${userApi.url}/${urls.requests.saveComment}/${walletId}/comment`,
      headers,
      data: {
        walletId: internalWalletId,
        comment,
      },
    });
  },
  newWalletTradeAnalyze: async function (
    wallets: string[],
    chain: string,
    startDate: string,
    endDate: string,
    headers = {}
  ) {
    return await backend.request({
      method: 'POST',
      url: `${userApi.url}/${urls.requests.newWalletTradeAnalyze}`,
      data: {
        wallets,
        chain,
        startDate,
        endDate,
      },
      headers,
    });
  },
};

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { Pagination, Table, Tag } from 'antd';

import { IMetaReport, userApi } from '../api/userApi';
import Loading from '../components/Loading';
import { RootState } from '../store/store.ts';
import { Report } from '../types';

const History = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const user = useSelector((state: RootState) => state.user);
  const [meta, setMeta] = useState<IMetaReport>({
    itemsPerPage: 10,
    itemCount: 10,
    totalItems: 0,
    currentPage: 1,
    totalPages: 1,
  });

  const getAnalyzeRequests = (currentPage: number) =>
    userApi
      .getWalletTradeAnalyzeRequests(currentPage, 10, {
        Authorization: `Bearer ${user.auth0Token}`,
      })
      .then((res) => {
        setMeta(res.data.meta);
        return res.data.data;
      });

  const { isLoading, data } = useQuery<Report[], Error>(
    ['walletTradeAnalyzeRequests', currentPage],
    () => getAnalyzeRequests(currentPage),
    {
      enabled: !!user.auth0Token,
      retry: 3,
    }
  );

  const getBadgeColorByStatus = (status: string) => {
    if (status === 'DONE') return 'green';
    if (status === 'IN_PROGRESS') return 'yellow';
    if (status === 'FAIL') return 'red';
  };

  const columns = [
    {
      title: 'Report ID (click for detailed info)',
      dataIndex: 'id',
      width: 450,
      render: (_: string, record: Report) => {
        return (
          <Link to={`/history/${record.id}`} className="min-w-[200px]">
            {record.id}
          </Link>
        );
      },
      key: 'address',
    },
    {
      title: 'Number of wallets',
      dataIndex: 'walletsCount',
      key: 'walletsCount',
      sorter: (a: Report, b: Report) =>
        (new Date(a.wallets[0].startDateExport) as never) -
        (new Date(b.wallets[0].startDateExport) as never),
      render: (_: string, record: Report) => {
        return <span>{record.wallets.length}</span>;
      },
      width: 180,
    },
    {
      title: 'Start date export',
      dataIndex: 'startDateExport',
      key: 'startDateExport',
      sorter: (a: Report, b: Report) =>
        (new Date(a.wallets[0].startDateExport) as never) -
        (new Date(b.wallets[0].startDateExport) as never),
      render: (_: string, record: Report) => {
        const date = new Date(record.wallets[0].startDateExport);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero indexed
        const year = date.getFullYear();
        return <span>{`${day}.${month}.${year}`}</span>;
      },
      width: 180,
    },
    {
      title: 'End date export',
      dataIndex: 'endDateExport',
      key: 'endDateExport',
      sorter: (a: Report, b: Report) =>
        (new Date(a.wallets[0].startDateExport) as never) -
        (new Date(b.wallets[0].startDateExport) as never),
      render: (_: string, record: Report) => {
        const date = new Date(record.wallets[0].endDateExport);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero indexed
        const year = date.getFullYear();
        return <span>{`${day}.${month}.${year}`}</span>;
      },
      width: 180,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (_: string, record: Report) => {
        return (
          <Tag color={getBadgeColorByStatus(record.status)}>
            {record.status}
          </Tag>
        );
      },
    },
    {
      title: 'Created date',
      dataIndex: 'dateOfRequest',
      key: 'dateOfRequest',
      render: (_: string, record: Report) => {
        const date = new Date(record.created);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return (
          <span>{`${day}.${month}.${year} ${hours}:${minutes}:${seconds}`}</span>
        );
      },
    },
  ];

  return (
    <div className="flex w-full justify-center">
      {!isLoading ? (
        <div className="flex flex-col justify-center text-neutral0 max-w-[1400px] w-full">
          <Table
            pagination={false}
            columns={columns}
            dataSource={data}
            bordered
            size="middle"
            scroll={{ x: '1600px', y: '100%' }}
          />
          <Pagination
            className="flex justify-center my-4"
            current={meta.currentPage}
            pageSize={meta.itemsPerPage}
            total={meta.totalItems}
            pageSizeOptions={['10']}
            onChange={(page) => setCurrentPage(page)}
          />
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default withAuthenticationRequired(History, {
  onRedirecting: () => <Loading />,
});

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Container } from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';

import { userApi } from './api/userApi.ts';
import Footer from './components/Footer';
import Loading from './components/Loading';
import NavBar from './components/NavBar';
import { setAuth0Token, setUser } from './store/slices/userSlice.ts';
import { RootState } from './store/store.ts';
import initFontAwesome from './utils/initFontAwesome';
import CheckAddress from './views/CheckAddress';
import History from './views/History';
import Home from './views/Home';
import Profile from './views/Profile';
import RequestDetails from './views/RequestDetails.tsx';
import ScumCheck from './views/ScumCheck';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';

initFontAwesome();

const App = () => {
  const { isLoading, isAuthenticated, getAccessTokenSilently, error } =
    useAuth0();
  const backendUser = useSelector((state: RootState) => state.user.user);

  const dispatch = useDispatch();

  useEffect(() => {
    const getUser = async () => {
      const token = await getAccessTokenSilently();
      const res = await userApi.getProfile({
        Authorization: `Bearer ${token}`,
      });
      dispatch(setUser(res.data));
      dispatch(setAuth0Token(token));
    };
    if (!isLoading && isAuthenticated) {
      getUser();
    }
  }, [isAuthenticated]);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading || backendUser?.email === '') {
    return <Loading />;
  }

  return (
    <BrowserRouter>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
        <Container className="flex-grow-1 mt-5 max-w-none">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/history" element={<History />} />
            <Route path="/scum-check" element={<ScumCheck />} />
            <Route path="/get-address-info" element={<CheckAddress />} />
            <Route path="/history/:id" element={<RequestDetails />} />
          </Routes>
        </Container>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

// eslint-disable-next-line import/no-default-export
export default App;

const contentData = [
  {
    title: 'Security First',
    link: 'https://auth0.com/docs/connections',
    description:
      "Never fall for a scam again. Enter any wallet address and instantly know if it's safe with Klugerhans.",
  },
  {
    title: 'Full Transaction Insight',
    link: 'https://auth0.com/docs/multifactor-authentication',
    description:
      "Dive deep into your wallet's activity. Get a comprehensive report on all recent transactions at your fingertips.",
  },
  {
    title: 'Profit & Loss Analytics',
    link: 'https://auth0.com/docs/anomaly-detection',
    description:
      "Stay on top of your investments. Klugerhans calculates your PNL, ensuring you're always informed and ahead.",
  },
  {
    title: 'Enhanced Trading Strategy',
    link: 'https://auth0.com/docs/rules',
    description:
      'Master your game with insights on win rate and more. Let Klugerhans guide you to smarter, safer crypto decisions.',
  },
];

export default contentData;

import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const Hero = () => {
  const navigate = useNavigate();
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const onItemClick = (type: 'checkScum' | 'getAddressData') => {
    if (!isAuthenticated) {
      loginWithRedirect();
    } else {
      if (type === 'checkScum') {
        navigate('/scum-check');
      } else {
        navigate('/get-address-info');
      }
    }
  };

  return (
    <div className="flex flex-col justify-center items-center text-center hero my-5">
      {/*<BackgroundSVG />*/}
      <img
        className="mb-3 animated-logo"
        src="/logo.svg"
        alt="React logo"
        width="120"
      />
      <h1 className="mb-4 font-black">Welcome to Klugerhans</h1>

      <p className="text-headline6">
        Dive right in – verify a wallet against scams or get comprehensive
        transaction analytics.
      </p>
      <div className="flex-col gap-5 md:gap-10 md:flex-row w-full flex text-center hero my-5 justify-center items-center h-[100%]">
        <div
          onClick={() => onItemClick('getAddressData')}
          className="w-full md:w-[400px] text-xl rounded-xl hover:opacity-80 duration-200 cursor-pointer h-[200px] bg-neutral600 text-neutral0 flex justify-center items-center"
        >
          <span className="max-w-[200px]">New wallet report (xlsx)</span>
        </div>
        <div
          onClick={() => onItemClick('checkScum')}
          className="w-full md:w-[400px] text-xl rounded-xl hover:opacity-80 duration-200 cursor-pointer h-[200px] bg-neutral600 text-neutral0 flex justify-center items-center"
        >
          Check smart-contract
        </div>
      </div>
    </div>
  );
};

export default Hero;

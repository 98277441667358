import { ChangeEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { FrownOutlined, SmileOutlined } from '@ant-design/icons';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Input, message, notification, Select } from 'antd';
import { AxiosError } from 'axios';

import { userApi } from '../api/userApi';
import Loading from '../components/Loading';

const { Search } = Input;
const ScumCheck = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [chain, setChain] = useState('ETH');

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (isHoneypot: boolean) => {
    api.open({
      message: 'Checking Result:',
      description: isHoneypot
        ? 'This address is honeypot'
        : 'This address is NOT honeypot',
      icon: isHoneypot ? (
        <FrownOutlined style={{ color: '#108ee9' }} />
      ) : (
        <SmileOutlined style={{ color: '#108ee9' }} />
      ),
    });
  };

  const onSearch = async () => {
    if (!inputValue.trim()) {
      message.error('Please enter a value before searching.');
      return;
    }

    const token = await getAccessTokenSilently();
    try {
      setLoading(true);
      const res = await userApi.analyzeWalletOnHoneypot(inputValue, chain, {
        Authorization: `Bearer ${token}`,
      });
      setTimeout(() => {
        openNotification(res.data.isHoneypot);
        setLoading(false);
      }, 1500);
    } catch (error: unknown) {
      if (typeof error === 'object' && error !== null && 'response' in error) {
        const axiosError = error as AxiosError<{ message: string[] }>;
        if (axiosError.response?.data?.message) {
          toast.error(axiosError.response?.data?.message);
        } else {
          toast.error('Something went wrong!');
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center gap-4 gap-x-10 text-center hero my-5">
      {contextHolder}
      <video width="180" height="200" autoPlay muted>
        <source src="/anim.mp4" type="video/mp4" />
      </video>
      <Select
        showSearch
        defaultValue={'ETH'}
        aria-required={true}
        placeholder="Chain"
        style={{ width: 120 }}
        optionFilterProp="label"
        onChange={(value) => setChain(value)}
        options={[
          {
            value: 'ETH',
            label: 'Ethereum',
          },
          {
            value: 'BASE',
            label: 'Base',
          },
          {
            value: 'BSC',
            label: 'BNB Smart Chain',
          },
        ]}
      ></Select>
      <Search
        onInput={(e: ChangeEvent<HTMLInputElement>) =>
          setInputValue(e.target.value)
        }
        onSearch={onSearch}
        className="w-[600px] h-[50px]"
        placeholder="Paste an address here"
        enterButton="Check"
        size="large"
        loading={loading}
      />
    </div>
  );
};

export default withAuthenticationRequired(ScumCheck, {
  onRedirecting: () => <Loading />,
});

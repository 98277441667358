import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Auth0Provider } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import store from './store/store.ts';
import history from './utils/history.ts';
import App from './App.tsx';
import { getConfig } from './config.ts';

import './index.css';

interface AppState {
  returnTo?: string;
}

const onRedirectCallback = (appState?: AppState) => {
  localStorage.setItem('loadingAuth', 'true');
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};
// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
  },
};

const queryClient = new QueryClient();
const rootElement = document.getElementById('root');

if (!rootElement) {
  throw new Error('Root element not found');
}

const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <Auth0Provider {...providerConfig}>
        <App />
        <ToastContainer />
      </Auth0Provider>
    </QueryClientProvider>
  </Provider>
);

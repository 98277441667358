import { ChangeEvent, useState } from 'react';
import { Button, DatePicker, Input, message, Select } from 'antd';
import dayjs from 'dayjs';

import Loading from '../components/Loading';

const { RangePicker } = DatePicker;
import { toast } from 'react-toastify';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { RangePickerProps } from 'antd/es/date-picker';
import { AxiosError } from 'axios';

import { userApi } from '../api/userApi';

const { TextArea } = Input;

const CheckAddress = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [isSearchDisabled, setIsSearchDisabled] = useState(true);
  const [inputValue, setInputValue] = useState(''); // Используйте строку, а не массив
  const [chain, setChain] = useState('ETH');

  const [dates, setDates] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
    dayjs().subtract(1, 'day').subtract(1, 'month'),
    dayjs().subtract(1, 'day'),
  ]);

  const disabledDate = (currentDate: dayjs.Dayjs) => {
    return currentDate && currentDate.isAfter(dayjs().subtract(1, 'day'));
  };

  const onSearch = async () => {
    const wallets = inputValue
      .split(/[\s,]+/)
      .filter(
        (address) => address !== '' && /^0x[a-fA-F0-9]{40}$/.test(address)
      );

    if (wallets.length === 0 || wallets.length > 50) {
      message.error(
        'Please enter at least one valid address and no more than 50.'
      );
      return;
    }

    const token = await getAccessTokenSilently();
    try {
      setLoading(true);
      await userApi.newWalletTradeAnalyze(
        wallets,
        chain,
        dates[0].format('YYYY-MM-DD'),
        dates[1].format('YYYY-MM-DD'),
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setTimeout(() => {
        message.success('Your request was successful!', 5);
        setInputValue('');
        setDates([
          dayjs().subtract(1, 'day').subtract(1, 'month'),
          dayjs().subtract(1, 'day'),
        ]);
      }, 1500);
    } catch (error: unknown) {
      if (typeof error === 'object' && error !== null && 'response' in error) {
        const axiosError = error as AxiosError<{ message: string[] }>;
        if (typeof axiosError.response?.data?.message === 'object') {
          axiosError?.response?.data?.message?.forEach((errMsg: string) => {
            toast.error(errMsg);
          });
        } else if (typeof axiosError.response?.data?.message === 'string') {
          toast.error(axiosError?.response?.data?.message);
        } else {
          toast.error('Something went wrong!');
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const onDatesChange: RangePickerProps['onChange'] = (selectedDates) => {
    if (selectedDates && selectedDates[0] && selectedDates[1]) {
      setDates([selectedDates[0], selectedDates[1]]);
    }
  };

  const dateFormat = 'DD.MM.YYYY';

  const validateAddresses = (input: string) => {
    const addresses = input.split(/[\s,]+/).filter((address) => address !== ''); // Фильтрация пустых строк
    const isValid =
      addresses.every((address) => /^0x[a-fA-F0-9]{40}$/.test(address)) &&
      addresses.length <= 50;
    setIsSearchDisabled(!isValid);

    return isValid;
  };

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    validateAddresses(newValue);
  };

  return (
    <div className="flex flex-col items-center gap-4 gap-x-10 text-center hero my-5">
      <TextArea
        value={inputValue}
        onChange={handleInputChange}
        className="w-[700px] h-[100px]"
        placeholder="Paste addresses here, separated by a comma, space, or comma with space"
        autoSize={{ minRows: 3, maxRows: 12 }}
      />
      <Select
        showSearch
        defaultValue={'ETH'}
        aria-required={true}
        placeholder="Chain"
        style={{ width: 120 }}
        optionFilterProp="label"
        onChange={(value) => setChain(value)}
        options={[
          {
            value: 'ETH',
            label: 'Ethereum',
          },
          {
            value: 'BASE',
            label: 'Base',
          },
          {
            value: 'BSC',
            label: 'BNB Smart Chain',
          },
        ]}
      ></Select>
      <RangePicker
        disabledDate={disabledDate}
        defaultValue={dates}
        onChange={onDatesChange}
        format={dateFormat}
      />
      <Button
        loading={loading}
        onClick={onSearch}
        disabled={isSearchDisabled}
        className="ant-btn ant-btn-primary ant-btn-lg"
      >
        Create report
      </Button>
    </div>
  );
};

export default withAuthenticationRequired(CheckAddress, {
  onRedirecting: () => <Loading />,
});

import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DownloadOutlined } from '@ant-design/icons';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { Button, Input, Table, Tag } from 'antd';
import debounce from 'lodash/debounce';

import { userApi } from '../api/userApi';
import Loading from '../components/Loading';
import { RootState } from '../store/store.ts';
import { Report, Wallet } from '../types';

const RequestDetails = () => {
  const { TextArea } = Input;
  const user = useSelector((state: RootState) => state.user);
  const [comments, setComments] = useState<Record<string, string>>({});
  const debouncedSaveCommentRef = useRef<
    Record<string, (value: string) => void>
  >({});

  const { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
      navigate('/');
    }
  }, [id, navigate]);
  const getAnalyzeRequestById = async (): Promise<Report> => {
    if (!id) {
      throw new Error('ID is not provided');
    }
    const res = await userApi.getWalletTradeAnalyzeRequestById(id, {
      Authorization: `Bearer ${user.auth0Token}`,
    });
    return res.data;
  };

  const { isLoading, data, error } = useQuery<Report, Error>(
    ['walletTradeAnalyzeRequests', id],
    getAnalyzeRequestById,
    {
      enabled: !!id && !!user.auth0Token,
      retry: 3,
    }
  );

  console.log('Query Status:', isLoading, 'Error:', error);

  const getBadgeColorByStatus = (status: string) => {
    if (status === 'DONE' || status === 'SUCCESS') return 'green';
    if (status === 'IN_PROGRESS') return 'yellow';
    if (status === 'FAIL') return 'red';
  };

  const columns = [
    {
      title: 'Address',
      dataIndex: 'address',
      width: 380,
      render: (_: string, record: Wallet) => {
        return <span className="min-w-[200px]">{record.address}</span>;
      },
      key: 'address',
    },
    {
      title: 'Chain',
      dataIndex: 'chain',
      key: 'chain',
      width: 80,
      render: (_: string, record: Wallet) => {
        return <span>{record.chain}</span>;
      },
    },
    {
      title: 'Winrate %',
      dataIndex: 'totalWinratePercent',
      key: 'totalWinratePercent',
      width: 110,
      sorter: (a: Wallet, b: Wallet) =>
        a.totalWinratePercent - b.totalWinratePercent,
      render: (_: string, record: Wallet) => {
        return <span>{record.totalWinratePercent}%</span>;
      },
    },
    {
      title: 'Total PNL',
      dataIndex: 'totalPnlUSD',
      key: 'totalPnlUSD',
      width: 140,
      sorter: (a: Wallet, b: Wallet) => a.totalPnlUSD - b.totalPnlUSD,
      render: (_: string, record: Wallet) => {
        return <span>${record.totalPnlUSD}</span>;
      },
    },
    {
      title: 'Trades count',
      dataIndex: 'tradesCount',
      key: 'tradesCount',
      width: 100,
      render: (_: string, record: Wallet) => {
        return <span>{record.tradesCount}</span>;
      },
    },
    {
      title: 'Start date export',
      dataIndex: 'startDateExport',
      key: 'startDateExport',
      width: 120,
      sorter: (a: Wallet, b: Wallet) =>
        (new Date(a.startDateExport) as never) -
        (new Date(b.startDateExport) as never),
      render: (_: string, record: Wallet) => {
        const date = new Date(record.startDateExport);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero indexed
        const year = date.getFullYear();
        return <span>{`${day}.${month}.${year}`}</span>;
      },
    },
    {
      title: 'End date export',
      dataIndex: 'endDateExport',
      key: 'endDateExport',
      width: 120,
      sorter: (a: Wallet, b: Wallet) =>
        (new Date(a.endDateExport) as never) -
        (new Date(b.endDateExport) as never),
      render: (_: string, record: Wallet) => {
        const date = new Date(record.endDateExport);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero indexed
        const year = date.getFullYear();
        return <span>{`${day}.${month}.${year}`}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 120,
      render: (_: string, record: Wallet) => {
        return (
          <Tag color={getBadgeColorByStatus(record.status)}>
            {record.status}
          </Tag>
        );
      },
    },
    {
      title: 'Report date',
      dataIndex: 'updated',
      key: 'updated',
      width: 120,
      render: (_: string, record: Wallet) => {
        if (!record.updated) {
          return null;
        }

        const date = new Date(record.updated);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return (
          <span>{`${day}.${month}.${year} ${hours}:${minutes}:${seconds}`}</span>
        );
      },
    },
    {
      title: 'Download',
      dataIndex: 'fileUrl',
      key: 'fileUrl',
      width: 100,
      render: (_: string, record: Wallet) => {
        return (
          <a
            className="flex justify-around items-center"
            download
            href={record.fileUrl}
          >
            <Button
              disabled={
                record.status === 'IN_PROGRESS' ||
                record.status === 'FAIL' ||
                !record.fileUrl
              }
              type="dashed"
              icon={<DownloadOutlined />}
              size="middle"
            />
            .xls
          </a>
        );
      },
    },
    {
      title: 'Comment',
      dataIndex: 'userComment',
      key: 'userComment',
      width: 300,
      render: (_: string, record: Wallet) => {
        return (
          <TextArea
            value={comments[record.id] || record.userComment || ''}
            onChange={(e) => {
              const value = e.target.value;
              setComments((prev) => ({ ...prev, [record.id]: value }));

              if (!debouncedSaveCommentRef.current[record.id]) {
                debouncedSaveCommentRef.current[record.id] = debounce(
                  (value: string) => {
                    userApi.saveComment(id || 'none', record.id, value, {
                      Authorization: `Bearer ${user.auth0Token}`,
                    });
                  },
                  1500
                );
              }

              debouncedSaveCommentRef.current[record.id](value);
            }}
            placeholder="Leave a comment here..."
            id={record.id}
            defaultValue={record?.userComment || ''}
          />
        );
      },
    },
  ];

  return (
    <div className="m-auto max-w-[1400px] flex w-full justify-center">
      {!isLoading && data ? (
        <div className="w-full -mt-5">
          <Link to="/history">
            <img
              className="w-[50px] mb-6 cursor-pointer hover:opacity-80 transition-all duration-50"
              src="/arrow-left.svg"
              alt="Back"
            />
          </Link>
          <div className="flex flex-col mb-4">
            <div>Report ID: {data.id}</div>
            <div>
              Created date:{' '}
              {(() => {
                const date = new Date(data.created);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();

                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');
                const seconds = String(date.getSeconds()).padStart(2, '0');

                return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
              })()}
            </div>
            <div>
              Updated date:{' '}
              {data.updated &&
                (() => {
                  const date = new Date(data.updated);
                  const day = String(date.getDate()).padStart(2, '0');
                  const month = String(date.getMonth() + 1).padStart(2, '0');
                  const year = date.getFullYear();

                  const hours = String(date.getHours()).padStart(2, '0');
                  const minutes = String(date.getMinutes()).padStart(2, '0');
                  const seconds = String(date.getSeconds()).padStart(2, '0');

                  return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
                })()}
            </div>
            <div>
              Status:{' '}
              <Tag color={getBadgeColorByStatus(data.status)}>
                {data.status}
              </Tag>
            </div>
          </div>
          <div className="flex flex-col justify-center text-neutral0 max-w-[1400px] w-full">
            <Table
              pagination={false}
              columns={columns}
              dataSource={data.wallets}
              bordered
              size="middle"
              scroll={{ x: '1600px', y: '100%' }}
            />
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default withAuthenticationRequired(RequestDetails, {
  onRedirecting: () => <Loading />,
});

import { Fragment } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import Content from '../components/Content.tsx';
import Hero from '../components/Hero.tsx';
import Loading from '../components/Loading.tsx';

const Home = () => (
  <Fragment>
    <Hero />
    <hr />
    <Content />
  </Fragment>
);

export default withAuthenticationRequired(Home, {
  onRedirecting: () => <Loading />,
});

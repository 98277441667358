import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IUser {
  id: string;
  email: string;
  creditBalance: number | null;
}

interface UserState {
  user: IUser | null;
  isLoading: boolean;
  error: string | null;
  auth0Token: string;
}

const initialState: UserState = {
  user: null,
  isLoading: false,
  error: null,
  auth0Token: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    setAuth0Token: (state, action: PayloadAction<string>) => {
      state.auth0Token = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
});

export const { setUser, setLoading, setError, setAuth0Token } =
  userSlice.actions;

export default userSlice.reducer;

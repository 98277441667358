import { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import contentData from '../utils/contentData.ts';

class Content extends Component {
  render() {
    return (
      <div className="next-steps my-5">
        <h2 className="mb-5 text-headline6 text-center">
          Turn wallet mysteries into clear stories.
        </h2>
        <Row className="d-flex justify-content-between">
          {contentData.map((col, i) => (
            <Col key={i} md={5} className="mb-4">
              <h6 className="mb-3">
                <span className="text-blue">
                  <FontAwesomeIcon icon="link" className="mr-2" />
                  {col.title}
                </span>
              </h6>
              <p>{col.description}</p>
            </Col>
          ))}
        </Row>
      </div>
    );
  }
}

// eslint-disable-next-line import/no-default-export
export default Content;

import { useSelector } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import Loading from '../components/Loading';
import { RootState } from '../store/store.ts';

export const ProfileComponent = () => {
  const { user, logout } = useAuth0();
  const backendUser = useSelector((state: RootState) => state.user.user);
  return (
    <Container className="flex items-center justify-between">
      <Row className="align-items-center profile-header text-center text-md-left">
        <div className="mr-2">
          <img
            src={user?.picture}
            alt="Profile"
            className="w-[100px] rounded-circle img-fluid profile-picture mb-md-0"
          />
        </div>
        <Col md className="flex flex-col items-start">
          <h2 className="lead mb-0.5">{user?.email}</h2>
          <h2 className="mb-2">Credit balance: {backendUser?.creditBalance}</h2>
          <p className="text-sm text-muted">User ID: {backendUser?.id}</p>
        </Col>
      </Row>
      <div
        onClick={() => logout()}
        className="cursor-pointer hover:text-neutral400"
      >
        Logout
      </div>
    </Container>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
